<template>
  <div class="newDetails">
    <div class="news_nav"></div>
    <div class="news_banner"></div>
    <div class="newsTitle">
      <div class="title">{{ data.title }}</div>
      <div class="text">
        <span class="textTitle">{{ titleText }}</span>
        <span class="source">发布人: {{ data.source }}</span>
        <span class="viewCount">浏览次数: {{ data.viewCount }}</span>
        <template v-if="containLabel">
          <template v-for="(item, index) in data.labelStr">
            <span :key="index" class="labelStr">{{ item }}</span>
          </template>
        </template>
      </div>
    </div>
    <div v-html="initElement(data.content)" class="context"></div>
    <FootMain :isBanner="isBanner"></FootMain>
    <div class="tonoen">{{ rootNewsList }}</div>
  </div>
</template>
<script>
import FootMain from '@/components/pc/commom/footmain/index.vue'

export default {
  components: {
    FootMain
  },
  data() {
    return {
      data: '',
      isBanner: false,
      containLabel: false
    }
  },
  computed: {
    rootNewsList() {
      let query = this.$route.query
      let data = {}
      // let dataList = this.$store.state.newsList
      // let data = dataList.filter(item => item.id == query.name)
      // if (!data || data.length == 0) {
      this.$http.request({ url: 'news/detail', method: 'POST', data: { id: query.name } }).then(res => {
        if (res.returnCode == 1) {
          this.data = res.data
          this.data.labelStr = this.data.labelStr.split(',')
          this.containLabel = this.data.labelStr.length()>0
          // this.data.createTime = this.data.createTime.split(' ')[0].split('-')
        }
      })
      // } else {
      // this.data = data[0]
      // }
      return data
    },
    titleText() {
      let data = this.data
      if (!data) return ''
      return `发布日期: ${data.createTime.substring(0, 10)}`
    }
  },
  methods: {
    initElement(data) {
      if (!data) return
      let first = data.substring(0, 1)
      // console.log(first, data)
      if (data != undefined && data != '' && first == '"') {
        return JSON.parse(data)
      }
      return data
    }
  }
}
</script>
<style scoped lang="scss">
.news_nav {
  height: 3.947368rem;
  width: 100%;
  background-color: #7435f2;
}
.news_banner {
  height: 12.236842rem;
  width: 100%;
  background-image: url('~@/assets/img/pc/about/about_banner.jpg');
  background-size: 100% 100%;
}
.context {
  width: 36.8421rem;
  margin: 0 auto;
}
.newsTitle {
  width: 36.8421rem;
  margin: 0 auto 0.7894rem;
  text-align: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 0.5263rem;
  .title {
    font-size: 0.6578rem;
    margin: 1.3157rem 0 0.5263rem;
  }
  .text {
    display: flex;
    align-items: center;
    font-size: 0.421rem;
    justify-content: center;
    color: #666;
    span {
      margin: 0 0.5263rem 0 0;
    }
  }
}
.labelStr {
  display: inline-block;
  min-width: 1.315789rem;
  max-width: 2.631578rem;
  height: 0.789473rem;
  line-height: 0.789473rem;
  text-align: center;
  background-color: #387dee;
  color: #fff;
  font-size: 0.368421rem;
  margin-right: 0.394736rem;
  border-radius: 0.105263rem;
  padding: 0 0.131578rem;
  overflow: hidden;
}
.tonoen {
  display: none;
}
</style>
